@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./base.module.scss";
@import "./main.scss";

@import "./menu";
@import "~leaflet/dist/leaflet.css";

/*pages */
@import "../../pages/Home/home";
@import "../../pages/Auth/Login/login";
@import "../../pages/Auth/Register/register";
@import "../../pages/Auth/Rules/About/rules";
@import "../../pages/Self/Profile/profile";
@import "../../pages/Admin/GtuQuestion/gtu";
@import "../../pages/Admin/Categories/category";
@import "../../pages/Post/Form/postCreate";
@import "../../pages/Post/Search/search";
@import "../../pages/Post/View/viewPost";
@import "../../pages/Admin/Users/user";
@import "../../pages/Admin/News/news";
@import "../../pages/Chat/Message/message";
@import "../../pages/Chat/StartChat/startChat";
@import "../../pages/Admin/Reports/reports";
@import "../../pages/Admin/Reasons/reasons";

/*components */
@import "../../components/UserPopover/popover";
@import "../../components/Form/Input/input";
@import "../../components/Button/buttons";
@import "../../components/Card/card";
@import "../../components/Form/Autocomplete/autocomplete";
@import "../../components/Table/table";
@import "../../components/Form/Editor/editor";
@import "../../components/Text/text";
@import "../../components/Carousel/carousel";
