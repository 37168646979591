.rules {
	position: relative;

	.rules-title {
		text-align: center;
		width: 100%;
		margin: 2% 0;
	}

	.rules-card {
		margin: 3% auto;
		background-color: $color-white;
		padding: 1rem 1.5rem;
		border: 1px solid $color-gray;
		box-shadow: $box-shadow;
		border-radius: 3px;

		button {
			width: 100%;

			@include breakpoint {
				width: 200px;
			}
		}
	}

	.rules-answers {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0.5rem 1.5rem;
		min-height: 54px;

		button {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin: 1.2rem auto;
			width: 300px;
			font-size: 1.4rem;
		}

		label {
			font-size: 1.3rem;
			margin-left: 0.2rem;
			margin-right: 0.5rem;
		}
	}

	.rules-actions {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
		margin-top: 5%;

		@include breakpoint {
			flex-direction: row;
		}

		button {
			margin: 5px 0;

			@include breakpoint {
				margin: 0 5px;
			}
		}
	}
}
