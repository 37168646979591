.profile {
    max-width: 600px !important;

    @include breakpoint {
		max-width: 1400px !important;
	}

    .profile-title {
        text-align: center;
		width: 100%;
		margin-top: 1%;
		margin-bottom: 1%;

        @include breakpoint {
			margin-top: 2%;
			margin-bottom: 2%;
		}
    }

    .profile-forms {
        margin: 2% 4% 5%;

        @include breakpoint {
            margin-bottom: 0;
        }

        .profile-section {
            margin-bottom: 1%;
            margin-left: 1%;
        }

        .profile-card {
            background-color: $color-white;
            padding: .8rem;
            border: 1px solid $color-gray;
            box-shadow: $box-shadow;
            border-radius: 3px;

            @include breakpoint {
                .form-group-two {
                    display: grid;
                    grid-gap: calc(2 * 8px);
                    grid-template-columns: repeat(auto-fill, minmax(var(--col, 40%), 1fr));
                    margin: .6rem 0;
                }

                .form-group-three {
                    display: grid;
                    grid-gap: calc(3 * 8px);
                    grid-template-columns: repeat(auto-fill, minmax(var(--col, 30%), 1fr));
                    margin: .6rem 0;
                }
            }

            &:nth-child(2n) .input-group {
                margin-top: 2%;

                @include breakpoint {
                    margin-top: 0;
                }
            }
        }
    }
}
