.gtu-question-form {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5rem 1.5rem;
	transition: all 0.3s;
	min-height: 55px;
}

.gtu-answer-form {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5rem 1.5rem;
	min-height: 54px;
}
