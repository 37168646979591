.modal-contact {
    .reason {
        margin: .4rem auto 0;
        text-align: center;
        font-size: 1.2rem;
    }

    .contacts {
        margin: .8rem auto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .contact-card {
            width: 100%;
            text-align: center;

            &:first-child {
                margin-right: 1rem;
            }
        }
    }
}
