.file-field {
	margin: 0.8rem 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.input-group {
		width: 100%;
		align-self: center;
	}
}
