.register {
	max-width: 600px !important;

	.register-title {
		text-align: center;
		width: 100%;
		margin-top: 1%;
		margin-bottom: 1%;

		@include breakpoint {
			margin-top: 4%;
			margin-bottom: 4%;
		}
	}
	.register-methods {
		display: flex;
		flex-direction: column;
		.register-method {
			background-color: $color-white;
			padding: 1rem 2rem;
			border: 1px solid $color-gray;
			box-shadow: $box-shadow;
			border-radius: 3px;
			display: flex;
			flex-direction: column;

			.register-method-title {
				font-size: 1.3rem;
				font-weight: bold;
				text-align: center;
				margin-bottom: 1rem;
			}

            .form {
                position: relative;
            }

			.register-method-utils {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;

				@include breakpoint {
					flex-direction: row;
					justify-content: space-between;
					align-items: space-between;
				}

				a {
					text-align: center;
					color: $color-white;
					font-weight: bold;
					width: 100%;
					padding: 0.5rem 0.3rem;
					border-radius: 5px;
				}

				@include breakpoint {
					a {
						margin: 0.5rem 0 1rem;
					}
				}
			}
		}
	}
}
