*,
::after,
::before {
	box-sizing: border-box;
}

.ReactCollapse--collapse {
	transition: height 500ms;
}

body {
	margin: 0;
	font-family: $font-family !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: $color-white scale;
	height: 100vh;
	overflow: hidden;

	background: $background-color;

	.disabled {
		color: $color-disabled;

		&:hover {
			transform: unset;
			cursor: not-allowed;
		}
	}

	#root {
		height: 100vh;
	}

	a,
	button,
	input,
	select {
		transition: all 0.3s;
		cursor: pointer;
	}

	.app {
		width: 100%;
		height: 100%;
	}

	.app-content {
		height: calc(100% - ($header-height + $footer-height));
		overflow-y: auto;
		overflow-x: hidden;
		margin: 0 auto;

		.page {
			padding: 5px;
			max-width: 1400px;
			margin: 0 auto;
			height: 100%;
		}
	}

	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: $color-primary;
		border-radius: 6px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: $color-secondary;
		border-radius: 6px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: $color-secondary-dark;
	}
}

h1 {
	font-size: 33px;
	font-weight: 700;
	line-height: 1.2;
	color: $color-primary-dark;
}

h2 {
	font-size: 28px;
	font-weight: 700;
	line-height: 1.2;
	color: $color-primary-dark;
}

h3 {
	font-size: 23px;
	font-weight: 700;
	line-height: 1.2;
	color: $color-primary-dark;
}

h4 {
	font-size: 17px;
	font-weight: 700;
}

.button-facebook {
	text-align: center;
	width: 100%;
	padding: 0.5rem 0.3rem;
	color: $color-white;
	font-weight: bold;
	border-radius: 3px;
	border: transparent;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	img {
		width: 2.4rem;
		margin-right: 0.5rem;
	}
}
button:disabled {
	background-color: $color-gray;

	color: $color-white;
	border-color: $color-gray;
}
input[type="checkbox"]:disabled {
	background-color: $color-gray;
	color: $color-gray;
	border-color: $color-gray;
}

textarea {
	min-height: 5em;
	border-radius: 3px;
}

.spin {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 3px solid $color-white;
	border-radius: 50%;
	border-top-color: $color-primary;
	-webkit-animation: spin 1s ease-in-out infinite;
	animation: spin 1s ease-in-out infinite;

	@keyframes spin {
		to {
			-webkit-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		to {
			-webkit-transform: rotate(360deg);
		}
	}
}

.dialog {
	z-index: 1500;
}

//BEGIN Spinner css
.overlay {
	position: fixed; /* Positioning and size */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	z-index: 10000;

	.overlay-spin {
		display: none;
		position: absolute;
		top: 50%;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		width: 71px;
		height: 71px;
		border: 6px solid $color-white;
		border-top-color: $color-primary;
		border-right-color: $color-primary;
	}
}

//END Spinner css
