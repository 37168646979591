$buttons-action-container-height: 66px;

.CGU-container {
	overflow: auto;
	height: calc(100% - $buttons-action-container-height);
	p {
		margin-top: 1em;
		margin-bottom: 1em;
		padding-right: 2px;
	}
	ul {
		display: block;
		list-style-type: disc;
		margin-top: 1em;
		margin-bottom: 1 em;
		margin-left: 0;
		margin-right: 0;
		padding-left: 40px;
	}
}
