.post-view {
	width: 95%;
	.post-medias {
		display: flex;
		flex-direction: column-reverse;
		@include breakpoint {
			flex-direction: row;
			margin-bottom: 1.5rem;
		}
	}

	.exchange-item + .exchange-item::before {
		white-space: pre;
		content: ", ";
	  }

	.post-aside {
		@include breakpoint {
			margin-left: 1.5rem;
			width: 100%;
		}

		.post-meta {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			p:nth-child(1n) {
				margin: 0.8rem 0;
			}
		}

		.post-desc {
			margin: 0.8rem 0;
		}

		.button {
			width: 100%;
			margin-bottom: 1.2rem;

			@include breakpoint {
				margin-bottom: 0;
			}
		}
	}

	.post-map {
		margin: 1.5rem 0 0.8rem;
	}
}
