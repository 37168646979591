.input-control {
	border: 1px solid $color-gray;
	background: $background-color !important;
	border-radius: 3px;
	display: block;
	width: 100%;
	outline: 0;

	&:focus {
		border-color: $color-primary;
		border-width: 1px !important;
	}
}

.input-error {
	color: $color-red;
	font-style: italic;
}

.input-upload {
	cursor: pointer;
	display: flex;
	justify-content: center;
	border-radius: 3px;
	width: 100%;
	background: $color-secondary !important;
	border: 1px solid $color-gray;
	color: $color-white;

	@include breakpoint {
		width: 200px;
	}

	svg {
		margin-top: 2px;
	}
}

.checkbox {
	border-radius: 3px;
	cursor: pointer;
}

.checkbox.outline-red {
	outline: 2px solid $color-red;
	border: 1px solid $color-red;
}

.checkbox.outline-green {
	outline: 2px solid $color-green;
	border: 1px solid $color-green;
}