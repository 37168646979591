.search-posts {
	width: 95%;
	.filters-card {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		@include breakpoint {
			.form-group {
				display: inline-block;
				width: 50%;
			}
		}
	}

	@include breakpoint {
		.sort-fields {
			display: inline-block;

			.sort-fields-content {
				.input-group {
					width: 100%;
				}
			}
		}
	}

	.create-alert {
		width: 100%;
		@include breakpoint {
			float: right;
			width: 20%;
		}
	}

	.posts-list {
		.post {
			transition: all 0.3s;
			display: flex;
			flex-direction: row;
			padding: 0.6rem 0.8rem;
			height: 166px;

			.post-picture {
				height: 100%;
				box-sizing: border-box;
				min-width: auto;
				margin: auto 0;
				overflow: hidden;

				img {
					height: 100%;
					object-fit: contain;
					border-radius: 3px;
					width: 120px;
				}
			}

			.post-info {
				overflow: hidden;
				padding-left: 10px;
				display: flex;
				flex-direction: column;
				.post-meta {
					display: flex;
					flex-direction: column;

					.post-meta__secondary {
						background-color: $color-secondary;
						border-radius: 3px;
						color: $color-white;
						padding: 2px;
						font-size: 14px;
					}
					.post-meta__primary {
						background-color: $color-primary;
						border-radius: 3px;
						color: $color-white;
						padding: 2px;
						font-size: 14px;
					}
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
	}
}
