$chat-header-height: 85px;
$chat-send-button: 44px;

.chat-container {
	width: 95%;
	margin: 0 auto;
	display: flex;
	height: 100%;
	overflow: hidden;
	position: relative;
}

.chat-message-content {
	display: flex;
	overflow: auto;
	overflow: auto;
	padding: 0.75rem;
	flex-direction: column-reverse;
	height: calc(100% - ($chat-header-height + $chat-send-button));
}

.chat-message-form {
	display: flex;
	height: $chat-send-button;
	align-items: center;
	width: 100%;
	padding-bottom: 5px;

	.input-group {
		label {
			display: none;
		}
		padding: 0 10px;
		width: 100%;
		input {
			border-radius: 30px;
		}
	}
}

.chat-room-list__header {
	display: flex;
	height: $chat-header-height;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid $color-gray;
}

.chat-message-header {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
	color: $color-primary-dark;
	height: $chat-header-height;
	border-bottom: 2px solid $color-gray;
	padding: 0.5rem;
	height: $chat-header-height;

    .chat-message-report {
        padding: .25rem .75rem;
    }
}
