.button {
	font-size: 1rem;
	padding: 0.5rem 0.3rem;
	min-width: 150px;
	border-radius: 3px;
	color: $color-white;
	text-align: center;
	border: 1px solid;

	&:hover {
		opacity: 0.9;
	}
}
