.section.data {
	margin: 0.6rem auto;
	width: 95%;
	overflow: auto;
	max-height: 85%;

	.global-filter {
		display: flex;
	}

	.pagination {
		text-align: center;
		padding: 0.8rem 0.5rem;
		border: 1px solid $color-gray;

		.button:nth-child(1) {
			margin-right: 0.8rem;
		}
	}

	.table {
		width: 100%;
		margin: 0.8rem 0;

		th,
		td {
			border: 1px solid $color-gray;
		}

		.table-header-cell {
			padding: 1rem 0.5rem;

			.cell-text {
				float: left;
			}

			.cell-sort {
				float: right;
			}
		}

		.table-body-cell {
			padding: 0.8rem 0.5rem;

			.cell-action {
				display: flex;
				flex-direction: row;
			}
		}
	}
}
