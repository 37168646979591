.category-form {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5rem 1.5rem;
	transition: all 0.3s;
	min-height: 55px;
}

.sub-category-form {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5rem 1.5rem;
	min-height: 54px;
}
