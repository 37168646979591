.suggestions {
	background: $background-color;
	max-height: 150px;
	overflow-y: auto;
	position: absolute;
	width: 100%;
	border-radius: 3px;
	border: 1px solid $color-gray;
	box-shadow: $box-shadow;
	z-index: 1;

	.suggestions-item {
		transition: all 0.3s;
		padding: 0.5rem 0.8rem;
		cursor: pointer;

		&:hover {
			background-color: $color-primary;
			color: $color-white;
		}
	}
}

.suggestions__spin {
	position: absolute;
	top: 30px;
	right: 10px;
	border-color: $color-primary !important;
	border-top-color: $color-secondary !important;
	display: block !important;
}
