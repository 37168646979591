.add-post {
	width: 90%;
}

.post-types {
	display: flex;
	flex-direction: column;
	align-items: center;

	input[type="radio"] {
		display: none;
	}

	@include breakpoint {
		flex-direction: row;
		justify-content: center;
	}

	.post-type {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 1.2rem auto;

		button.bg-primary {
			margin-top: 0.6rem;
		}
	}
}

.post-info-create {
	.file-field {
		align-items: center;
		margin: 0.8rem 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.preview {
			display: flex;
			flex-direction: column;
			@include breakpoint {
				flex-direction: row;
			}

			.file-preview {
				display: flex;
				flex-direction: column;
				margin-left: unset;
				padding-top: 10px;
				position: relative;
				

				@include breakpoint {
					margin-left: 5px;
					
					img {
						max-height: 170px;
					}
				}

				

				.bg-deletion {
					cursor: pointer;
					width: 21px;
					background-color: $color-red;
					color: $color-white;
					position: absolute;
					right: 0;
					
				}
			}
		}

		.delete-img {
			margin-left: unset;
			background-color: $color-red;
			margin-top: 2px;
			width: 100%;
			height: 42px;

			@include breakpoint {
				margin-left: 5px;
				margin-top: 17px;
				width: unset;
			}
		}

		.input-group {
			width: 100%;
			justify-self: center;
			align-self: center;
		}
	}
}

.create-actions {
	display: flex;
	flex-direction: column;

	.button {
		margin: 0.5rem 0;
	}

	@include breakpoint {
		flex-direction: row;
		justify-content: space-between;

		.button {
			margin: 0;
		}
	}
}