.header-user {
	background-color: $color-white;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid $color-black;
	color: $color-black;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	margin-left: 9px;

	&:hover {
		color: $color-primary;
		border-color: $color-primary;
	}

	.popover {
		border: 1px solid $color-gray;
		position: absolute;
		box-shadow: $box-shadow;
		border-radius: 20px;
		background: $color-white;
		top: 70px;
		right: 0;
		font-weight: 700;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		z-index: 200;
		cursor: pointer;
		width: 200px;

		.popover-item--logout {
			border-radius: 0px 0px 20px 20px;
			color: $color-red !important;

			&:hover {
				background-color: $color-red-light !important;
			}
		}

		.popover-item--profile {
			border-radius: 20px 20px 0 0;
		}

		.popover-item {
			display: flex;
			flex-direction: row;
			padding: 14px 5px;
			transition: all 0.5s ease;
			color: $color-black;

			svg {
				padding-bottom: 3px;
			}

			&:hover {
				background-color: $color-primary;
				color: $color-white;
			}
		}
	}
}
