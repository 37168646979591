.home {
	.home-actions {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 90%;
		margin: 0 auto;
		min-height: 20%;

		@include breakpoint {
			flex-direction: row;
		}

		.home-action {
			background-color: $color-white;
			padding: 1rem 2rem;
			border: 1px solid $color-gray;
			box-shadow: $box-shadow;
			border-radius: 3px;
			flex: 0.4;
			margin: 5px 0;

			img {
				width: 100px;
				float: right;
				margin-top: -25px;
			}

			span {
				color: $color-primary;
				font-size: 20px;
			}

			p {
				padding-top: 20px;
			}

			&:hover {
				border: 1px solid $color-primary;
			}
		}
	}

	.home-news {
		background-color: $color-white;
		padding: 1rem 2rem;
		border: 1px solid $color-gray;
		box-shadow: $box-shadow;
		border-radius: 3px;
		width: 90%;
		margin: 0 auto;
		text-align: center;

		p {
			display: block;
			margin-top: 1em;
			margin-bottom: 1em;
			margin-left: 0;
			margin-right: 0;
		}

		img {
			max-width: 80vw;
			margin: 0 auto;

			@include breakpoint {
				max-width: 50%;
			}
		}
	}
}