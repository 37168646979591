.header {
	position: relative;
	height: $header-height;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	width: 95%;
	border-bottom: 1px solid $color-gray;
	max-width: 1400px;

	//for subMenu
	.sub-menu {
		position: absolute;
		top: calc($header-height - 10px);
		z-index: 100;
	}

	.ReactCollapse--collapse {
		.ReactCollapse--content {
			display: flex;
			flex-direction: column;
			background-color: $background-color;
			box-shadow: $box-shadow;
			border-radius: 3px;
			border: 1px solid $color-gray;
			padding: 8px 0;

			a {
				font-size: 15px;
				padding: 5px 10px !important;
				&:hover {
					background-color: $color-gray;
				}
			}

			svg {
				margin-top: 1px;
			}
		}
	}

	.active {
		color: $color-primary;
	}

	.header-links {
		display: none;

		p {
			border-right: 2px solid $color-gray;
			margin-left: 10px;
			height: 30px;
		}

		@include breakpoint {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0 5px;

			&:hover {
				color: $color-primary;
			}
		}

		svg {
			margin-bottom: 3px;
			padding: 1px;
		}
	}

	@include breakpoint {
		.header-click-menu {
			display: none;
		}
	}
}

.header-logo {
	height: 100%;
}

.sidebar {
	transition: all 0.3s linear;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: $background-color;
	z-index: 10001;

	@include breakpoint {
		display: none;
	}

	.sidebar-header {
		border-bottom: 1px solid $color-gray;
		height: $header-height;
		background-color: transparent;
		display: flex;
		overflow: hidden;
	}

	.lougout-link {
		color: $color-red;

		&:hover {
			color: $color-red !important;
			background-color: $color-red-light;
		}
	}

	.active {
		color: $color-primary;
	}

	&.sidebar--active {
		width: 16em;
	}

	&.sidebar--closed {
		width: 0px;
		min-width: 0px;
	}

	.sidebar-links {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-overflow: ellipsis;
		overflow: hidden;

		a {
			display: flex;
			align-items: center;
			height: 2.3rem;

			padding: 20px;
			text-align: center;

			&:hover {
				color: $color-primary;
			}
		}
		.chat-message-link {
			display: flex;
			align-items: center;
			.chat-message-link__notification {
				top: unset;
				left: unset;
				position: unset;
				margin-left: auto;
				margin-right: 0.25rem;
			}
		}
	}

	.ReactCollapse--content {
		font-size: 15px;
		margin-left: 29px;
		border-left: 3px solid $color-primary;
	}
}

.footer {
	z-index: 1;
	width: 100%;
	height: $footer-height;
	background-color: $color-primary;
	color: $color-white;

	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 15px;

	@include breakpoint {
		font-size: unset;
	}

	&.hidden {
		display: none;
	}

	p {
		text-align: center;

		a {
			color: $color-secondary;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.chat-message-link {
	position: relative;

	.chat-message-link__notification {
		padding: 0 5px;
		background-color: $color-red;
		border-radius: 50%;
		min-width: 19px;
		height: 19px;
		position: absolute;
		top: -8px;
		color: $color-white;
		font-size: 14px;
		text-align: center;
		right: -11px;
	}
}
