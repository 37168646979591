.login {
	position: relative;

	.login-title {
		text-align: center;
		position: absolute;
		width: 100%;
		top: 2%;

		@include breakpoint {
			top: 5%;
		}
	}

	.login-methods {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: 100%;
		height: 100%;
		padding: 1rem 1rem;

		@include breakpoint {
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.login-method {
			position: relative;
			background-color: $color-white;
			padding: 1rem 2rem;
			border-radius: 3px;
			border: 1px solid $color-gray;
			box-shadow: $box-shadow;
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 600px;

			@include breakpoint {
				width: 90%;
				height: 65%;
				margin: 0 1rem;
			}

			.login-method-title {
				font-size: 1.3rem;
				font-weight: bold;
				text-align: center;
				margin-bottom: 1rem;
			}
		}
	}
}
